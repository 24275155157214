.chat__footer {
  padding: 10px 30px;
  background: rgba(0, 0, 0, 0.4);
  height: 60px;
  width: 100%;
  position: relative;

  @media only screen and (max-width: 600px) {
    padding-right: 15px;
  }

  input {
    background: none;
    border: none;
    outline: none !important;
    resize: none;
    color: rgba(255, 255, 255, .7);
    font-size: 14px;
  }


}

.form {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & svg {
    display: none !important;
  }
}

.message {
  width: 80%;
  height: 100%;
  border-radius: 10px;
  border: 1px solid #ddd;
  outline: none;
  padding: 15px 15px 15px 60px;
}

.chatBtn {
  margin-top: 0px !important;
}

.emoji-wrap {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

.emoji {
  position: relative;

  svg {
    font-size: 14px;
    color: #ffc848;
    cursor: pointer;
  }

  .emoji-picker-react {
    position: absolute;
    top: -330px;
    background-color: var(--popup-bg);
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.4);
    border-radius: 6px;
    border: none;

    .emoji-scroll-wrapper::-webkit-scrollbar {
      background-color: var(--popup-bg);
      width: 5px;

      &-thumb {
        background-color: var(--theme-button-color);
      }
    }

    .emoji-categories {
      button {
        filter: contrast(0);
      }
    }

    .emoji-search {
      background-color: transparent;
      border-color: var(--theme-button-color);
    }

    .emoji-group:before {
      background-color: var(--popup-bg);
    }
  }
}

.bot {
  background-color: var(--theme-button-color);
  border-radius: 180%;
  border: none;
  min-width: 39px;
  min-height: 39px;
  cursor: pointer;
}

.botimg {
  width: 25px;
}


/**
 * hg-theme-default theme
 */
 .simple-keyboard.hg-theme-ios {
  background-color: rgba(0, 0, 0, 0.0);
  border-radius: 0;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.simple-keyboard.hg-theme-ios.hg-theme-default .hg-row .hg-button {
  flex-grow: 1;
  cursor: pointer;
  max-width: initial;
}
.simple-keyboard.hg-theme-ios .hg-row {
  display: flex;
}
.simple-keyboard.hg-theme-ios .hg-row:not(:last-child) {
  margin-bottom: 5px;
}
.simple-keyboard.hg-theme-ios .hg-row .hg-button:not(:last-child) {
  margin-right: 5px;
}
.simple-keyboard.hg-theme-ios .hg-row:nth-child(2) {
  margin-left: 0px;
}
.simple-keyboard.hg-theme-ios.hg-theme-default {
  background-color: var(--popup-bg);
  padding: 5px;
  border-radius: 5px;
}
.simple-keyboard.hg-theme-ios.hg-theme-default.hg-layout-custom {
  background-color: #e5e5e5;
  padding: 5px;
}
.simple-keyboard.hg-theme-ios.hg-theme-default .hg-button {
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0;
  border-bottom: 1px solid #b5b5b5;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  font-weight: 400;
  font-size: 20px;
  height: 50px;
  background: rgba(0, 0, 0, 0.3);
  color: white;

}
.simple-keyboard.hg-theme-ios.hg-theme-default .hg-button:active,
.simple-keyboard.hg-theme-ios.hg-theme-default .hg-button:focus {
  background: var(--theme-button-color);
  color: white;
}
.simple-keyboard.hg-theme-ios.hg-theme-default .hg-button.hg-functionBtn {
  background: rgba(0, 0, 0, 0.7);
  color: white;
}
.simple-keyboard.hg-theme-ios.hg-theme-default .hg-button.hg-button-space,
.simple-keyboard.hg-theme-ios.hg-theme-default .hg-button.hg-button-shift,
.simple-keyboard.hg-theme-ios.hg-theme-default
  .hg-button.hg-button-shiftactivated {
    background: rgba(0, 0, 0, 0.70);
    color: white;
}
.simple-keyboard.hg-theme-ios.hg-theme-default .hg-button-space {
  width: 50%;

}
.simple-keyboard.hg-theme-ios.hg-theme-default .hg-button-enter {
  max-width: 110px;
  min-width: 110px;
}
.simple-keyboard.hg-theme-ios.hg-theme-default .hg-button-altright,
.simple-keyboard.hg-theme-ios.hg-theme-default .hg-button-back {
  min-width: 80px;
  max-width: 80px;
}

.keyboard-input {
  background: rgba(0, 0, 0, 0.50);
  color: white;
  padding: 20px;
}

.footer-shout-btn {
  background-color: var(--theme-button-color);
  border-radius: 180%;
  border: none;
  margin-left: 10px;
  width: 37px;
  height: 37px;
  display: flex; 
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.botimgttlbr-foot {
  width: 18px;
  height: 18px;
}
