.logo-home {
  width: 160px;

}

/* login forma */

.login-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 350px;
  padding: 40px;
  transform: translate(-50%, -50%);
  background-color: var(--theme-bg-color);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0, 0, 0, .6);
  border-radius: 14px;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 430px) {
    width: 300px;
  }

  @media only screen and (max-width: 360px) {
    width: 280px;

  }

  @media only screen and (max-width: 290px) {
    width: 100%;
  }

  & img {
    margin: 0 0 20px 0;
  }

  & .user-box {
    position: relative;
    margin-bottom: 40px;

    @media only screen and (max-width: 430px) {
      margin-bottom: 20px;
    }
  }
}

input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: var(--theme-color);
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}

input::placeholder {
  font-size: 13px;
  color: var(--button-inactive)!important;
}

select, textarea {
  font-size: 16px!important;
  color: white!important;
}

textarea::placeholder {
  font-size: 13px!important;
  color: var(--button-inactive)!important;
}

.form-p {
  font-size: 14px;
  color: #eee;
  font-weight: 200;

  @media only screen and (max-width: 460px) {
    font-size: 12px;
  }
}

button:disabled,
button[disabled] {
  border: none;
  background-color: #cccccc!important;
  color: #999;
  cursor: default;
}

/* info box */
.image-info {
  &-wrapper {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 25%;

    @media only screen and (max-width: 1050px) {
      width: 35%;
    }

    @media only screen and (max-width: 900px) {
      width: 45%;
    }

    @media only screen and (max-width: 700px) {
      width: 55%;
    }

    @media only screen and (max-width: 500px) {
      width: 65%;
    }

    @media only screen and (max-width: 450px) {
      width: 75%;
    }

    @media only screen and (max-width: 360px) {
      width: 85%;
    }

    @media only screen and (max-width: 300px) {
      width: 95%;
    }
  }

  &-sub {
    background-color: var(--theme-bg-color);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 14px;
    padding: 20px;
    color: white;
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 20px;

    & a {
      color: white;
      margin-top: 10px;
      display: inline-block;

    }
  }

  &-btn {
    cursor: pointer;
    background-color: var(--theme-bg-color);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 6px;
    color: white;
    font-size: 25px;
    font-weight: 200;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-prev {
      margin-right: 5px;
    }
  }

  &-icon {
    cursor: pointer;
    background-color: var(--theme-bg-color);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    color: white;
    display: flex;
    flex-direction: row;
    border-radius: 6px;
    height: 40px;
    width: 40px;
    padding: 10px;
    margin-right: 5px;
  }

  &-content {
    display: flex;
    flex-direction: row;
  }

  &-name {
    margin-bottom: 15px;
    font-weight: 100;
    font-size: 18px;
    border-bottom: 1px solid rgba(255, 255, 255, .5);
    padding-bottom: 10px;
  }

}

.logo-wrap {
  display: flex;
  justify-content: center;
}

.checkbox-wrap {
  display: flex;
  flex-direction: row;

  & input {
    width: 12px;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    background: transparent;
  }

  & p {
    margin-left: 10px;
  }

  & span {
    cursor: pointer;
    color: #fff;
    text-decoration: underline;
  }
}

.avatar-img-alt {
  min-width: 60px !important;
  min-height: 60px !important;
}

.appsdwnld-wrap {
  position: absolute;
  left: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: row;
 

  & img {
    width: 100px;
    margin-right: 10px;
    cursor: pointer;

    @media only screen and (max-width: 800px) {
      display: none;
    }
  }
}

.mobapp-ico {
  display: none;
  width: 30px !important;

  @media only screen and (max-width: 800px) {
    display: block !important;
  }
}

.onimageactivelogo {
  position: absolute;
  top: 30px;
  left: 30px;
  width: 150px;
}

.close-desc {
  background-color: var(--theme-button-color);
  color: white;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 20px;
  height: 20px;
  border-radius: 180%;
  display: flex;
  align-items: center;
  justify-content: center;

}

//dffdasf
/*Created by Prabir ku. Das*/

.ani-bg{
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  //background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  //background: linear-gradient(-45deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .4), rgba(58, 109, 240, .8), rgba(58, 109, 240, 1));
  //background: #23a6d5;
  background: linear-gradient(-45deg, #65747b, #4c5e67, #334753,#1a313f);
  background-size: 400% 400%;
  animation: gradient 60s ease infinite;
}

/*Animation keyframes*/

@keyframes gradient{
  0%{
      background-position: 0% 50%;
  }

  50%{
      background-position: 100% 50%;
  }

  100%{
      background-position: 0% 50%;
  }
}

.step {
  width: 14%;
  height: 10px;
  background-color: #333;
  border-right: 1px solid #fff;
}

.step-current {
  background-color: #555!important;
}

.step-done {
  background-color: #3a6df0;
}

.step-last {
  background-color: #3a6df0!important;
}

.step-wrong {
  background-color: #333;
}

.step:nth-of-type(1) {
  border-radius: 8px 0 0 8px;
}

.step:nth-of-type(7) {
  border-radius: 0 8px 8px 0;
  border-right: none;
}

.refresh-avatar {
  position: absolute;
  bottom: -20px;
  right: 100px;
  background: var(--theme-button-color);
  border-radius: 180%;
  padding: 2px;
  z-index: 1;
  cursor: pointer;
}