img {
    max-width: 100%;
}

.dark-light {
    position: fixed;
    bottom: 50px;
    right: 30px;
    background-color: var(--dropdown-bg);
    box-shadow: -1px 3px 8px -1px rgba(0, 0, 0, 0.2);
    padding: 8px;
    border-radius: 50%;
    z-index: 3;
    cursor: pointer;

    svg {
        width: 24px;
        flex-shrink: 0;
        fill: #ffce45;
        stroke: #ffce45;
        transition: 0.5s;
    }
}

.app {
    background-color: var(--theme-bg-color);
    width: 95vw;
    max-width: 2500px;
    height: 90vh;
    max-height: 1600px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    border-radius: 14px;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    font-size: 15px;
    font-weight: 500;
    box-shadow: 0 15px 25px rgba(0, 0, 0, .6);

    @media only screen and (max-width: 1300px) {
        min-width: 95vw;
        min-height: 90vh;

    }

    @media only screen and (max-width: 767px) {
        min-width: 100%;
        min-height: 100%;
        border-radius: 0 !important;
        position: fixed;
        inset: 0;

    }
}

.wrapper {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
}

.main-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
}

.content-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    color: var(--theme-color);
    padding: 20px 30px;
    overflow: auto;
    background-color: var(--theme-bg-color);

    &-img {
        border-radius: 180px;
    }



    @media only screen and (max-width: 600px) {
        padding: 15px 15px;
    }
}

.content-section {
    display: flex;
    flex-direction: column;
}

.is-on-mobile-img {
    min-width: 15px;
    min-height: 15px;
    max-width: 15px;
    max-height: 15px;
}

//novo

.slider-wrapper {
    margin: 5px 35px -5px 30px;
    position: relative;
    

    @media only screen and (max-width: 600px) {
        margin: 5px 15px -5px 15px;
    }

    @media only screen and (max-width: 350px) {
        margin: 0px 15px -5px 15px;
    }
}

.sidebar-right {
    position: fixed;
    top: 227px;
    right: 20px;
    z-index: 1;
    overflow: auto;
    width: 400px;
    height: calc(100% - 380px);
    padding-right: 10px;  

   @media only screen and (max-width: 1200px) {
        top: 280px;
        width: 300px;
        height: calc(100% - 433px);
    } 
    @media only screen and (max-width: 1100px) {
        top: 325px;
        height: calc(100% - 475px);
    }

    @media only screen and (max-width:650px) {
        display: none;
    }

    &_btn {
        position: absolute;
        bottom: 95px;
        right: 227px;
        z-index: 1;

        @media only screen and (max-width: 1200px) {
            right: 127px;
        } 

        @media only screen and (max-width:650px) {
            display: none;
        }
    }

}

.sidebar-right-mobile {
    //background: rgba(0, 0, 0, .3);
    overflow: auto;
    padding: 10px 25px;  
    max-height: 190px;
    margin-bottom: 10px;

    @media only screen and (max-width: 600px) {
        padding: 10px 10px 10px 15px;
    }

    @media only screen and (max-width: 430px) {
        min-height: 230px;
    }
}

.close-sidebar-right-mobile {
    background-color: var(--theme-button-color);
    color: white;
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -5px;
    bottom: -20px;
    cursor: pointer;
    border-radius: 180px;
    font-size: 14px;
    z-index: 10;

    p {
        font-size: 14px;
        margin-top: -5px;
        margin-left: -5px;
    }
}

.add-sidebar-right-mobile {
    background-color: var(--theme-button-color);
    color: white;
    position: absolute;
    right: 35px;
    bottom: -20px;
    cursor: pointer;
    border-radius: 180px;
    font-size: 12px;
    font-weight: 300;
    z-index: 10;
    padding: 5.5px 15px 5.5px 15px;
}