//news

.box {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    @media only screen and (max-width: 800px) {
        flex-direction: column-reverse;
    }

    &-column {
        flex-direction: column-reverse;

    }

    &-content {
        background-image: url("https://www.transparenttextures.com/patterns/cubes.png");
        background-color: var(--border-color);
        display: flex;
        flex-direction: column;
        width: 70%;
        padding: 25px 40px 25px 25px;
        border-radius: 20px 0 0 20px;
        align-self: stretch;
        overflow: hidden;
        position: relative;

        @media only screen and (max-width: 800px) {
            width: 100%;
            border-radius: 0 0 20px 20px;

        }

        &-column {
            width: 100%;
            border-radius: 0 0 20px 20px;
        }
    }

    &-poster {
        background-size: cover;
        width: 30%;
        background-color: white !important;
        border-radius: 0 20px 20px 0;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;

        @media only screen and (max-width: 800px) {
            height: 200px;
            width: 100%;
            border-radius: 20px 20px 0 0;
        }

        &-column {
            height: 200px;
            width: 100%;
            border-radius: 20px 20px 0 0;

        }
    }

    &-sub {
        text-transform: uppercase;
        font-size: 12px;
        margin-bottom: 20px;
        line-height: 1.2;
        text-decoration: none;
        color: #fff;
        cursor: pointer;
        width: fit-content;
    }

    &-meta {
        font-size: 13px;
        font-weight: 400;
        color: #fff;
        line-height: 1.6;

        &-item {
            color: white;
            word-break: break-word;
        }

        &-item:not(:last-child):after {
            content: "\A";
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: white;
            display: inline-block;
            margin-left: 10px;
            margin-right: 10px;
            margin-bottom: 2px;
        }
    }

    &-author {
        display: flex;
        align-items: left;
        margin-top: 15px;

        @media only screen and (max-width: 510px) {
            margin-bottom: 40px;
        }

        &-column {
            @media only screen and (max-width: 1200px) {
                margin-bottom: 40px;
            }
        }

        &.tips {
            flex-direction: column-reverse;
            align-items: flex-start;
        }

        &-img {
            width: 42px;
            height: 42px;
            border: 1px solid rgb(255 255 255 / 75%);
            padding: 1px;
            border-radius: 50%;
            background-color: white;
            object-fit: cover;
        }

        &-detail {
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &-name {
            font-size: 15px;
            color: #fff;
            font-weight: 500;
            line-height: 1.2;
            margin-bottom: 2px;
        }

        &-info {
            font-size: 12px;
            font-weight: 400;
            color: #fff;
            line-height: 1.2;
        }
    }

    &-title {
        font-size: 25px;
        font-weight: 600;
        letter-spacing: 1px;
        color: #fff;
        margin-bottom: 10px;
        line-height: 1.2;
    }
}

//templates
.twitter {
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
        linear-gradient(to right top,
            #66757f, #00ACEE, #36D8FF, #36D8FF);
}

.steam {
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
        linear-gradient(to right top,
            #00adee, #000000);
}

.reddit {
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
        linear-gradient(to right top,
            #ff4500, #5f99cf, #cee3f8);
}

.codepenio {
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
        linear-gradient(to right top,
            #0ebeff, #47cf73, #ae63e4, #fcd000, #ff3c41, #76daff);
}

.gogcom {
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
        linear-gradient(to right top,
            rgba(104, 0, 210, 1) 25%, rgba(199, 0, 234, 1) 100%);
}

.bloghr {
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
        linear-gradient(to right top,
            rgba(34, 34, 34, 1) 25%, rgba(177, 0, 0, 1) 100%);
}

.tiktok {
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
        linear-gradient(to right top,
            #F50A6B,
            #EE1170,
            #DB237B,
            #C53988,
            #B14D95,
            #A65A9B,
            #946BA7,
            #827DB0,
            #7787B8,
            #56A9CB,
            #2CD3E5);
}

.youtube {
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
        linear-gradient(to right top,
            #ff0000, #282828);
}

.craigslistorg {
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"), linear-gradient(90deg, rgba(125, 0, 125, 1) 0%, rgba(125, 0, 125, .3) 100%);


}

.google {
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
        linear-gradient(to right top,
            #4285f4, #34a853, #fbbc05, #ea4335);
}

.gmwcn {
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
        linear-gradient(to right top,
            #DF280F, #DF280F);
}

.dprkvok {
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
        linear-gradient(to right top,
            #024D9D, #024D9D);
}

.newspresscom {
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
        linear-gradient(to right top,
            rgba(14, 90, 169, 1) 0%, rgba(234, 88, 53, 1) 50%, rgba(254, 218, 33, 1) 100%);
}

.oglasnikhr {
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
        linear-gradient(to right top,
            rgba(21, 19, 21, 1) 0%, rgba(0, 161, 211, 1) 50%, rgba(238, 238, 238, 1) 100%);
}

.bughr {
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
        linear-gradient(to right top,
            rgba(241, 91, 36, 1) 0%, rgba(244, 171, 61, 1) 100%);
}

.chathr {
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
        linear-gradient(to right top,
            rgba(254, 170, 0, 0.8715861344537815) 0%, rgba(254, 181, 33, 1) 50%, rgba(254, 222, 75, 1) 100%);
}

.tassru {
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
        linear-gradient(to right top,
            rgba(0, 54, 160, 1) 0%, rgba(206, 42, 29, 1) 50%, rgba(247, 247, 247, 1) 100%);
}

.groundnews {
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
        linear-gradient(to right top,
            rgba(38, 38, 38, 1) 0%, rgba(209, 189, 145, 1) 50%, rgba(209, 189, 145, 1) 100%);
}

.instructables {
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
        linear-gradient(to right top,
            rgba(197, 230, 166, 1) 0%, rgba(189, 210, 166, 1) 50%, rgba(185, 190, 165, 1) 100%);
}

.jkrowling {
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
        linear-gradient(to right top,
            rgba(152, 153, 166, 1) 0%, rgba(167, 170, 164, 1) 50%, rgba(185, 190, 165, 1) 100%);
}

.agoodmovietowatch {
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
        linear-gradient(to right top,
            rgba(11, 9, 10, 1) 0%, rgba(22, 26, 29, 1) 50%, rgba(102, 7, 8, 1) 100%);
}

.notalwaysright {
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
        linear-gradient(to right top,
            rgba(60, 71, 75, 1) 0%, rgba(158, 239, 229, 1) 50%, rgba(192, 224, 222, 1) 100%);
}

.thisiswhyimbrokecom {
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
        linear-gradient(to right top,
            rgba(33, 45, 64, 1) 0%, rgba(54, 65, 86, 1) 50%, rgba(125, 78, 87, 1) 100%);
}

.goodnewsnetworkorg {
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
        linear-gradient(to right top,
            rgba(42, 157, 143, 1) 0%, rgba(233, 196, 106, 1) 50%, rgba(244, 162, 97, 1) 100%);
}

.onreadcom {
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
        linear-gradient(to right top,
            rgba(187, 148, 87, 1) 0%, rgba(67, 40, 24, 1) 50%, rgba(153, 88, 42, 1) 100%);
}

.autodeskinstructables {
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
        linear-gradient(to right top,
            rgba(27, 38, 59, 1) 0%, rgba(65, 90, 119, 1) 50%, rgba(119, 141, 169, 1) 100%);
}

.deathstats {
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
        linear-gradient(to right top,
            rgba(33, 37, 41, 1) 0%, rgba(52, 58, 64, 1) 50%, rgba(73, 80, 87, 1) 100%);
}

.britannicacom {
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
        linear-gradient(to right top,
            rgba(127, 85, 57, 1) 0%, rgba(176, 137, 104, 1) 50%, rgba(221, 184, 146, 1) 100%);
}

.mentalfloss {
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
        linear-gradient(to right top,
            rgba(239, 99, 81, 1) 0%, rgba(243, 131, 117, 1) 50%, rgba(247, 163, 153, 1) 100%);
}

.kantipurdaily {
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
        linear-gradient(to right top,
            rgba(219, 19, 60, 1) 0%, rgba(1, 56, 147, 1) 50%, rgba(255, 255, 255, 1) 100%);
}

.nairobinews {
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
        linear-gradient(to right top,
            rgba(244, 214, 9, 1) 0%, rgba(7, 133, 46, 1) 50%, rgba(15, 69, 170, 1) 100%);
}

.jamaicaobserver {
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
        linear-gradient(to right top,
            rgba(0, 0, 0, 1) 0%, rgba(241, 211, 5, 1) 50%, rgba(47, 135, 65, 1) 100%);
}

.britishantartic {
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
        linear-gradient(to right top,
            rgba(0, 153, 212, 1) 0%, rgba(0, 153, 212, 1) 100%, rgba(0, 153, 212, 1) 100%);
}

.sermitsiaqag {
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
        linear-gradient(to right top,
            rgba(35, 107, 218, 1) 0%, rgba(247, 247, 247, 1) 100%, rgba(255, 255, 255, 1) 100%);
}


.newsdate {
    background-color: var(--theme-button-color);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 0 16px 0;
    padding: 5px 10px 5px 10px;
    font-weight: 600;
    line-height: 1.2;
    font-size: 20px;
    z-index: 1;
    color: white;

    &-alt {
        background-color: var(--theme-button-color);
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 0 0 0 16px;
        padding: 5px 10px 10px 10px;
        font-weight: 600;
        line-height: 1.2;
        font-size: 20px;
       

        span {
            display: block;
            font-size: 14px;
            font-weight: 400;
        }
    }

    span {
        display: block;
        font-size: 14px;
        font-weight: 400;
    }
}